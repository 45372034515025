import React, { useState, useEffect } from 'react';
import '../../../assets/css/blogs.css';
import BlogCard from './BlogCardNew';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import Form from '../../../components/Forms/Form';
import { toast } from 'react-toastify';
import CloseIcon from '@mui/icons-material/Close';
import Book from '../../../assets/img/book.png';


const BlogsSection = ({ blogs, showReadMore }) => {
    const [topBlogs, setTopBlogs] = useState([]);
    const [otherBlogs, setOtherBlogs] = useState([]);
    const [pdfRequestModal, setPdfRequestModal] = useState(false);
    const [requestedFileKey, setRequestedFileKey] = useState('');
    const [caseStudyTitle,setCaseStudyTitle] = useState('')
    const [categories,setCategories] = useState('')


    const handlePdfRequest = (blog) => {
        setPdfRequestModal(true);
        setCaseStudyTitle(blog['title'])
        setCategories(blog['categories'].join(','));
        setRequestedFileKey(blog['file_key']);
    };

    const pdfRequestProps = {
        template: 1,
        form_name: "case-studies-pdf-request",
        fields: [
            { name: "name", required: true },
            { name: "email", required: true },
            { name: "phone", required: true },
            { name : "caseStudyTitle",required:true},
            { name : "categories",required:true}
        ],
        title: "See Sciative in Action & Drive Results",
        content_upper: "Make data-driven decisions with confidence. Dive into case studies and see how Sciative tackled challenges and delivered exceptional results. Get access to case studies and unlock exceptional insights that inspire you.",
        content_lower: "",
        requestedFileKey: requestedFileKey,
        recipients_group_id: 1,
        caseStudyTitle:caseStudyTitle,
        categories:categories,
        caseStudyDownload:true,
        page: window.location.pathname + window.location.search,
        handleApiResponse: (res) => {
            toast.dismiss();
            if (res && res.status && res.status === "success") {
                window.open(res.data.signed_url);
                toast.success('Form submitted successfully!');
                setPdfRequestModal(false);
            } else {
                toast.error(res.data);
            }
        }
    };

    useEffect(() => {
        if (blogs.length) {
            setTopBlogs([blogs[0], blogs[1], blogs[2]]);
            setOtherBlogs(blogs.slice(3));
        }
    }, [blogs]);

    return (
        <>
            <section className='w-100 row '>
                {topBlogs.map((blog, index) => (
                    <BlogCard
                        key={index}
                        blog={blog}
                        handlePdfRequest={handlePdfRequest}
                    />
                ))}
                {showReadMore && otherBlogs.length > 4 ? (
                    <>
                        {otherBlogs.slice(0, 4).map((blog, index) => (
                            // <div className='col-sm-12 col-md-3 p-1' key={index}>
                            <BlogCard
                                blog={blog}
                                handlePdfRequest={handlePdfRequest}
                            />
                            // </div>
                        ))}
                        <div className='col-xl-3 col-lg-4 col-md-6 col-sx-12 p-1 d-flex justify-content-center'>
                            <div className='p-1 ' style={{ width: '16em' }}>
                                <Link to={`/case-studies/category/all`}>
                                    <div className='corner read-more-card d-flex flex-column justify-content-center align-items-center gap-3 case-card-container'>
                                        <img className='icon-img' src={Book} alt="book" />
                                        <span className='text-white'>Read all Case Studies</span>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </>
                ) : (
                    otherBlogs.map((blog, index) => (
                        // <div className='col-sm-12 col-md-3 p-1' key={index}>
                        <BlogCard
                            blog={blog}
                            handlePdfRequest={handlePdfRequest}
                        />
                        // </div>
                    ))
                )}
            </section>
            <Modal
                show={pdfRequestModal}
                onHide={() => setPdfRequestModal(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                contentClassName="remove-white-bg"
                backdrop="static"
                centered
            >
                <Modal.Body className="p-0">
                    <CloseIcon onClick={() => setPdfRequestModal(false)} className='modal-close-icon' />
                    <Form {...pdfRequestProps} />
                </Modal.Body>
            </Modal>
        </>
    );
};

export default BlogsSection;
