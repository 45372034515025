import Footer from "../common/footer";
import Header from "../common/header";
import Ticker from "../common/ticker";
import { Accordion, Button, TabContainer, TabContent, TabPane } from 'react-bootstrap';
import AllRoutes from "../../AllRoutes";
import AOS from "aos";
import { useEffect, useState, useRef } from "react";
import "aos/dist/aos.css";

import { Tab, Col, Nav, Row } from "react-bootstrap";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { GlobalUrl, GlobalImg } from "../../global";
import '../../assets/css/main.scss';

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

import HotelCustomerCard from '../../components/hotels/hotelCustomCard'

import Box from "@mui/material/Box";
import hotelImg1 from '../../assets/img/hotel-customer/hotel-1.png';
import hotelImg2 from '../../assets/img/hotel-customer/hotel-2.png';
import hotelImg3 from '../../assets/img/hotel-customer/hotel-3.png';
import hotelImg4 from '../../assets/img/hotel-customer/hotel-4.png';
import hotelImg5 from '../../assets/img/hotel-customer/hotel-5.png';
import hotelImg6 from '../../assets/img/hotel-customer/hotel-6.png';
import hotelImg7 from '../../assets/img/hotel-customer/hotel-7.png';

import extendedImg1 from '../../assets/img/hotel-customer/extendedStays-1.png';
import extendedImg2 from '../../assets/img/hotel-customer/extendedStays-2.png';
import extendedImg3 from '../../assets/img/hotel-customer/extendedStays-3.png';
import extendedImg4 from '../../assets/img/hotel-customer/extendedStays-4.png';
import extendedImg5 from '../../assets/img/hotel-customer/extendedStays-5.png';
import extendedImg6 from '../../assets/img/hotel-customer/extendedStays-6.png';
import extendedImg7 from '../../assets/img/hotel-customer/extendedStays-7.png';
import extendedImg8 from '../../assets/img/hotel-customer/extendedStays-8.png';



import boutiqueImg1 from '../../assets/img/hotel-customer/boutiqueProperties-1.png';
import boutiqueImg2 from '../../assets/img/hotel-customer/boutiqueProperties-2.png';
import boutiqueImg3 from '../../assets/img/hotel-customer/boutiqueProperties-3.png';
import boutiqueImg4 from '../../assets/img/hotel-customer/boutiqueProperties-4.png';
import boutiqueImg5 from '../../assets/img/hotel-customer/boutiqueProperties-5.png';
import boutiqueImg6 from '../../assets/img/hotel-customer/boutiqueProperties-6.png';
import boutiqueImg7 from '../../assets/img/hotel-customer/boutiqueProperties-7.png';
import boutiqueImg8 from '../../assets/img/hotel-customer/boutiqueProperties-8.png';
import boutiqueImg9 from '../../assets/img/hotel-customer/boutiqueProperties-9.png';


import budget1 from '../../assets/img/hotel-customer/budgetProperties-1.png';
import budget2 from '../../assets/img/hotel-customer/budgetProperties-2.png';
import budget3 from '../../assets/img/hotel-customer/budgetProperties-3.png';
import budget4 from '../../assets/img/hotel-customer/budgetProperties-4.png';
import budget5 from '../../assets/img/hotel-customer/budgetProperties-5.png';
import budget6 from '../../assets/img/hotel-customer/budgetProperties-6.png';
import budget7 from '../../assets/img/hotel-customer/budgetProperties-7.png';
import budget8 from '../../assets/img/hotel-customer/budgetProperties-8.png';
import min_15 from '../../assets/img/hotels/slider_image/1-img.jpg';
import revpar from '../../assets/img/hotels/slider_image/2-img.jpg';
import comp_rev from '../../assets/img/hotels/slider_image/3-img.jpg';
import HotelHeader from "../hotels/hotelHeader";
import HotelHeaderMobile from "../hotels/hotelHeaderMobile";
import HotelFaqSection from "../hotels/hotelFaqSection";
import HotelBgcta from "../hotels/hotelbgcta";
import Form from "../Forms/Form";
import { toast } from "react-toastify";
import casebg from "../../assets/img/home/casee-bg.jpg";
import Modal from 'react-bootstrap/Modal';
import CloseIcon from '@mui/icons-material/Close';
import InnerCard from "../retail/innercard";
import axios from 'axios';

import { Link } from "react-router-dom";
import Recoginition from "../retail/recognition";
import CapteraAchievements from "../travel/capterra-achievvement";

gsap.registerPlugin(ScrollTrigger);




const HotelCustomer = () => {
    const [RATitle, setRATitle] = useState('');
    const [RAUrl, setRAUrl] = useState('');
    const [RAImg, setRAImg] = useState('');

    const [showHeader1, setShowHeader1] = useState(false);
    const [showHeader2, setShowHeader2] = useState(false);
    const end_point = "hotelfaq";

    const [selectedKey, setSelectedKey] = useState('mainfirst');
    const handleSelect = (key) => {
        setSelectedKey(key);
    };
    let isMobileLayout = window.matchMedia("(max-width: 1100px)").matches;

    const [isMobileScreen, setIsMobileScreen] = useState(isMobileLayout)




    useEffect(() => {
        if (!isMobileScreen) {
            let ctx = gsap.context(() => {

                const isMobile = window.matchMedia("(max-width: 768px)").matches;
                setIsMobileScreen(isMobile)

                gsap.set(".h-customer-right:not(:first-child)", { opacity: 0, scale: 0.5 });

                const setActiveSlide = (activeIndex) => {
                    const contentBlocks = document.querySelectorAll(".h-customer-left");
                    const images = document.querySelectorAll(".h-customer-right");

                    contentBlocks.forEach((block, index) => {
                        if (index === activeIndex) {
                            block.classList.add("showcase-slider-item-active");
                            if (isMobile) {
                                block.classList.remove("showcase-slider-inactive");
                            }
                        } else {
                            block.classList.remove("showcase-slider-item-active");
                            if (isMobile && index < activeIndex) {
                                block.classList.add("showcase-slider-inactive");
                            }
                        }
                    });

                    images.forEach((image, index) => {
                        if (index === activeIndex) {
                            gsap.to(image, {
                                opacity: 1,
                                scale: 1,
                                duration: 0.5
                            });
                        } else {
                            gsap.to(image, { opacity: 0, scale: 1, duration: 0.5 });
                        }
                    });
                };
                const animation = gsap.to(".h-customer-right:not(:first-child)", {
                    duration: 0.5,
                    stagger: 1,
                    paused: true
                });

                if (isMobile) {
                    gsap.set(".h-customer-right:not(:first-child)", { opacity: 1, scale: 1 });
                    ScrollTrigger.create({
                        trigger: ".gallery",
                        start: "top 20%",
                        end: "bottom bottom",
                        pin: ".rightblock",
                        scrub: true,
                        // markers: true,
                        animation: animation,
                    });
                    const contentBlocks = document.querySelectorAll(".h-customer-left");
                    contentBlocks.forEach((block, index) => {
                        ScrollTrigger.create({
                            trigger: block,
                            start: "top 85%",
                            end: "bottom center",
                            onEnter: () => setActiveSlide(index),
                            onLeaveBack: () => {
                                if (index === 0) {
                                    setActiveSlide(0);
                                } else {
                                    setActiveSlide(index - 1);
                                }
                            },
                            // markers: true,
                            scrub: true
                        });
                    });

                    setActiveSlide(0);
                }
                else {

                    ScrollTrigger.create({
                        trigger: ".gallery",
                        start: "top 28%",
                        end: "bottom bottom",
                        pin: ".rightblock",
                        scrub: true,
                        // markers: true,
                        animation: animation,
                    });
                    const contentBlocks = document.querySelectorAll(".h-customer-left");
                    contentBlocks.forEach((block, index) => {
                        ScrollTrigger.create({
                            trigger: block,
                            start: "top 75%",
                            end: "bottom center",
                            onEnter: () => setActiveSlide(index),
                            onLeaveBack: () => {
                                if (index === 0) {
                                    setActiveSlide(0);
                                } else {
                                    setActiveSlide(index - 1);
                                }
                            },
                            // markers: true,
                            scrub: true
                        });
                    });

                    setActiveSlide(0);

                }


            });


            return () => ctx.revert();
        }
    }, [selectedKey]);

    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, [])

    function scrollFunction() {
        var winScroll = document.body.scrollTop || document.documentElement.scrollTop;
        if (winScroll >= 80 && window.innerWidth >= 600) {
            setShowHeader2(false);
            setShowHeader1(true);
        }
        else if (winScroll >= 80 && window.innerWidth < 600) {
            setShowHeader1(false)
            setShowHeader2(true)

        }
        else {
            setShowHeader1(false)
            setShowHeader2(false)
        }
    }

    useEffect(() => {
        document.addEventListener('wheel', function () { scrollFunction() });
        document.addEventListener('scroll', function () { scrollFunction() });
        // window.addEventListener('wheel', function () { scrollFunction() });
        // window.addEventListener('scroll', function () { scrollFunction() });
    }, [])
    const formProps = {
        template: 4,
        form_name: 'hotel-main-page',
        fields: [
            { name: 'name', required: true },
            { name: 'email', required: true },
            { name: 'company', required: true },
            { name: 'phone', required: true },
            { name: 'message', required: true },
        ],
        recipients_group_id: 5,
        handleApiResponse: (res) => {
            toast.dismiss();
            if (res.status == "success") {
                toast.success('Form submitted successfully! We will get back to you in 2-3 days.');
            } else {
                toast.error(res.data)
            }
        }
    }

    const getRecentRATalk = async () => {
        try {
            const response = await axios.get(`/ra_talks/fetch_all_webinars`, {

                headers: {
                    'Content-Type': 'application/json'
                },
            });
            if (response.status === 200 && response.data !== undefined) {
                let raData = response.data.find(item => item['category'] === "hospitality");
                if (raData) {
                    setRATitle(raData.title);
                    setRAUrl('/road-ahead-talks/' + raData.seo.pageURl);
                    setRAImg(raData['pre-img']['pre-img-key']);
                }
            }

        } catch (err) {
            console.error("Error", err);
        }
    }
    const [caseStudies, setCaseStudies] = useState([]);

    const fetch_all_case_studies = async () => {
        try {
            const response = await axios.post(`/fetch_blogs_by_category`, {
                category: "Hospitality"
            }, {
                headers: {
                    'Content-Type': 'application/json'
                },

            });
            if (response.status == 200 && response.data !== undefined) {
                setCaseStudies(response.data);
            }
        } catch (err) {

        }
    }
    const [pdfRequestModal, setPdfRequestModal] = useState(false);
    const [requestedFileKey, setRequestedFileKey] = useState('');

    const handlePdfRequest = (file_key) => {
        setPdfRequestModal(true);
        setRequestedFileKey(file_key);
    }
    const pdfRequestProps = {
        template: 1,
        form_name: "case-studies-pdf-request",
        fields: [
            { name: "name", required: true },
            { name: "email", required: true },
            { name: "phone", required: true }
        ],
        title: "See Sciative in Action & Drive Results",
        content_upper: "Make data-driven decisions with confidence. Dive into case studies and see how Sciative tackled challenges and delivered exceptional results. Get access to case studies and unlock exceptional insights that inspire you.",
        content_lower: "",
        recipients_group_id: 1,
        requestedFileKey: requestedFileKey,
        handleApiResponse: (res) => {
            toast.dismiss();
            if (res && res.status && res.status === "success") {
                window.open(res.data.signed_url);
                toast.success('Form submitted successfully!');
                setPdfRequestModal(false);
            }
            else {
                toast.error(res.data);
            }
        }
    }
    useEffect(() => {
        fetch_all_case_studies();
        getRecentRATalk()
    }, [])

    let hotels = [
        {
            'img-id': hotelImg1,
            'title': 'Dynamic Pricing for Meals and Extra Beds',
            'description': 'Optimize revenue and guest satisfaction with dynamic pricing for meals and extra beds. Adjust rates based on real-time demand to enhance profitability and guest experience.'
        },
        {
            'img-id': hotelImg2,
            'title': 'Optimize Hotel Pricing with Seasonal & Travel Insights',
            'description': 'Transform your hotel’s pricing strategy with Zettaprice. We factor in seasonality and travel demand to deliver accurate demand predictions and optimize rates, boosting revenue and guest satisfaction.'
        },
        {
            'img-id': hotelImg3,
            'title': 'Smart Pricing & Segmentation',
            'description': 'Leverage Zettaprice to automatically handle pricing for stay dates, distribution channels, room types, and customer segments. Maximize revenue effortlessly for your hotel without manual adjustments.'
        },
        {
            'img-id': hotelImg4,
            'title': 'Advanced Analytics for Future Success',
            'description': 'Harness Zettaprice’s dynamic and forward-looking analytics to receive precise rate recommendations. Plan confidently with projections extending up to 365 days into the future.'
        },
        {
            'img-id': hotelImg5,
            'title': 'Instant Insights with Zero Errors',
            'description': 'Utilize Zettaprice’s rapid market data integration to eliminate processing delays and errors. Hotels can swiftly adapt revenue strategies, potentially boosting revenue by up to 20% with real-time insights.'
        },
        {
            'img-id': hotelImg6,
            'title': 'Maximize Occupancy with Nested Virtual Inventory',
            'description': 'Revamp your hotel booking strategy with Nested Virtual Inventory. Prevent room vacancies and optimize pricing for premium rooms to boost occupancy, profitability, and guest loyalty.'
        },
        {
            'img-id': hotelImg7,
            'title': 'Automated Reporting Efficiency',
            'description': 'Streamline your operations with Zettaprice’s automated daily and weekly reports. Save valuable time and resources by letting our system handle the creation and distribution of reports for your hotel stakeholders.'
        },

    ]
    let boutique = [
        {
            'img-id': boutiqueImg1,
            'title': 'AI-Driven Pricing for Boutique Hotels',
            'description': 'Boost boutique hotel efficiency with Zettaprice’s AI-driven automated pricing. Automatic price changes simplify rate management, with manual adjustments available if needed, reducing work hours by 40%.'
        },
        {
            'img-id': boutiqueImg2,
            'title': 'Dynamic Pricing for Boutique Excellence',
            'description': 'Enhance revenue and guest satisfaction at your boutique property with dynamic pricing for meals and extra beds. Adjust rates based on real-time demand to improve profitability and guest experience.Transform your hotel’s pricing strategy with Zettaprice. We factor in seasonality and travel demand to deliver accurate demand predictions and optimize rates, boosting revenue and guest satisfaction.'
        },
        {
            'img-id': boutiqueImg3,
            'title': 'Perfect Boutique Hotel Pricing with Seasonal Trends',
            'description': 'Enhance your boutique hotel’s pricing with Zettaprice. Leverage seasonality and travel demand insights to predict demand and optimize rates, driving revenue and guest satisfaction.'
        },
        {
            'img-id': boutiqueImg4,
            'title': 'Empower Your Revenue Strategy',
            'description': 'Leverage Zettaprice to automatically manage pricing for stay dates, distribution channels, room types, and customer segments. Maximize revenue effortlessly for your boutique without manual adjustments.'
        },
        {
            'img-id': boutiqueImg5,
            'title': 'Streamlined Automation for Boutique Properties.',
            'description': 'Focus on strategic planning and let Zettaprice’s controlled automation handle rate adjustments with demand. Seize every revenue opportunity effortlessly with our system.'
        },
        {
            'img-id': boutiqueImg6,
            'title': 'Tailor Your Revenue Approach',
            'description': 'Supports a customized, segmented approach to room pricing for boutique properties. Zettaprice enables flexible pricing strategies, allowing your revenue team to craft targeted marketing efforts.'
        },
        {
            'img-id': boutiqueImg9,
            'title': 'Real-Time Insights for Boutique Success',
            'description': 'Discover Zettaprice’s rapid data integration to cut processing delays and errors. Quickly adapt your revenue strategies with real-time insights, potentially increasing revenue by up to 20%.'
        },
        {
            'img-id': boutiqueImg7,
            'title': 'Fill Vacancies with Nested Virtual Inventory',
            'description': 'Optimize your boutique property’s booking strategy with Nested Virtual Inventory. Prevent room vacancies and maximize premium room pricing to enhance occupancy, profitability, and guest loyalty.'
        },
        {
            'img-id': boutiqueImg8,
            'title': 'Effortless Reporting Automation.',
            'description': 'Streamline your operations with Zettaprice’s automated reporting. Save time and resources with daily and weekly reports, ensuring your team receives the insights needed with minimal effort.'
        },

    ]


    let budget = [
        {
            'img-id': budget1,
            'title': 'AI-Driven Efficiency for Budget Hotels',
            'description': 'Streamline budget hotel management with Zettaprice’s AI-driven automated pricing. Our system handles price changes automatically, with manual adjustments available if needed, cutting work hours by up to 40%.'
        },
        {
            'img-id': budget2,
            'title': 'Dynamic Pricing for Budget Property Optimization',
            'description': 'Maximize revenue and guest satisfaction at your budget property with dynamic pricing for meals and extra beds. Adjust rates based on real-time demand to optimize profitability and meet guest needs.'
        },
        {
            'img-id': budget3,
            'title': 'Optimize Budget Hotel Pricing with Local Transport Insights',
            'description': 'Elevate your budget hotel’s pricing strategy using Zettaprice’s integration of transport data from buses, trains, and economy flights. Leverage local travel trends to fine-tune rates, enhance revenue, and boost guest satisfaction.'
        },
        {
            'img-id': budget6,
            'title': 'Maximize Every Booking Opportunity',
            'description': 'Leverage Zettaprice to automatically manage pricing for stay dates, distribution channels, room types, and customer segments. Maximize revenue effortlessly for your budget property without manual adjustments.'
        },
        {
            'img-id': budget4,
            'title': 'Automated and Strategic Forecasting',
            'description': 'Utilize Zettaprice automated forecasting to save time and effort. Our forward-looking analytics deliver rate recommendations for up to five years, supporting strategic decision-making.'
        },
        {
            'img-id': budget5,
            'title': 'Real-Time Insights for Budget Efficiency.',
            'description': 'Leverage Zettaprice rapid data integration to eliminate delays and errors for budget properties. Quickly adapt revenue strategies to boost revenue by up to 20% with real-time insights.'
        },
        {
            'img-id': budget7,
            'title': 'Avoid Vacancies with Nested Virtual Inventory',
            'description': 'Transform your budget property’s booking strategy with Nested Virtual Inventory. Prevent room vacancies and optimize pricing for premium rooms to enhance profitability and customer loyalty.'
        },
        {
            'img-id': budget8,
            'title': 'Effortless Reporting Automation',
            'description': 'Streamline your operations with Zettaprice’s automated reporting. Save time and resources with daily and weekly reports, ensuring your team receives the insights needed with minimal effort.'
        }

    ]


    let extendedStays = [
        {
            'img-id': extendedImg2,
            'title': 'Dynamic Pricing for Extended Stay Comfort',
            'description': 'Maximize revenue and guest satisfaction with dynamic pricing for meals and extra beds. Adjust rates based on real-time demand to enhance profitability and meet guest needs.'
        },
        {
            'img-id': extendedImg1,
            'title': 'AI-Driven Pricing for Extended Stays',
            'description': 'Optimize extended stay management with Zettaprice’s AI-driven automated pricing. Automatic updates handle rates efficiently, with manual adjustments possible when necessary, saving up to 40% in work hours.'
        },
        {
            'img-id': extendedImg3,
            'title': 'Refine Extended Stay Pricing with Events & Seasons Insights',
            'description': 'Optimize pricing for extended stays with Zettaprice. Integrate seasonality and travel demand factors to forecast demand accurately and adjust rates effectively, boosting revenue and guest satisfaction.'
        },
        {
            'img-id': extendedImg4,
            'title': 'Maximize Every Booking Opportunity',
            'description': 'Leverage Zettaprice to automatically manage pricing for stay dates, distribution channels, room types, and customer segments. Maximize revenue effortlessly without manual adjustments.'
        },
        {
            'img-id': extendedImg5,
            'title': 'Automated Forecasting and Future Planning',
            'description': 'Simplify forecasting with Zettaprice’s automated solutions. Our predictive analytics provide rate recommendations up to 365 days in advance, allowing you to stay ahead of market trends and make informed decisions.'
        },
        {
            'img-id': extendedImg6,
            'title': 'Instant Market Insights, Zero Errors',
            'description': 'Leverage Zettaprice rapid data integration to minimize delays and errors. Quickly adapt your revenue strategies with real-time insights, potentially increasing revenue by up to 20%.'
        },
        {
            'img-id': extendedImg7,
            'title': 'Optimize Booking with Nested Virtual Inventory',
            'description': 'Transform your booking strategy with Nested Virtual Inventory. Prevent room vacancies and optimize pricing for premium rooms, enhancing occupancy, profitability, and guest loyalty.'
        },
        {
            'img-id': extendedImg8,
            'title': 'Effortless Reporting Automation',
            'description': 'Streamline your operations with Zettaprice’s automated reporting. Save time and resources with daily and weekly reports, ensuring stakeholders receive the insights they need with minimal effort.'
        }

    ]




    return (
        <HelmetProvider>
            <Helmet>
                <title>Zettaprice Success: Customer Stories & Insights | Sciative</title>
                <link rel="canonical" href={`${GlobalUrl}${AllRoutes.hotelcontact}`} />
                <meta charset="utf-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="csrf_token" content="" />
                <meta name="theme-color" content="#ffffff" />
                <meta name="_token" content="" />
                <meta name="robots" content="noodp" />
                <meta name="description" content="Discover how Zettaprice's Revenue Management System empowers customers to optimize pricing and maximize revenue. Explore our success stories and case studies." />
                <meta property="og:locale" content="en_US" />
                <meta property="og:title" content="Zettaprice Success: Customer Stories & Insights | Sciative" />
                <meta property="og:description" content="Discover how Zettaprice's Revenue Management System empowers customers to optimize pricing and maximize revenue. Explore our success stories and case studies." />
                <meta property="og:hashtag" content="#ai" />
                <meta property="og:url" content={`${GlobalUrl}${AllRoutes.hotelcontact}`} />
                <meta property="og:site_name" content="Sciative Solutions - We Price Right" />
                <meta property="og:image" content={GlobalImg} />
                <meta property="og:type" content="website" />
            </Helmet>

            {
                showHeader1 ?
                    <section style={{ padding: '0', borderBottom: '1px solid #fff', width: '100%', position: 'fixed', top: '43px', height: '4.5em', zIndex: 100 }} className='banner_retail main_banner bg-black'>
                        <div className='container'>
                            <HotelHeader end_point={end_point} />
                        </div>
                    </section>
                    :
                    showHeader2 ?
                        <HotelHeaderMobile />
                        :
                        null
            }

            <section className="smallBanner hotel_customer">
                <div className="container"  >
                    <HotelHeader end_point={end_point} />
                    <p className="small_head">Customers</p>
                    <h1 className="about_para">With  AI Driven Dynamic Pricing Achieve a <br /> Remarkable 38% Boost in Sales.</h1>
                    <p className="about_para_2">
                        Our mission is to provide advanced pricing technology to enhance your revenue optimization and give you an edge in pricing strategy.
                    </p>

                    <div>
                        {/* <Button className="transparent-button">Discover more! </Button> */}
                        <a href={AllRoutes.hotelcontact}>
                            <button className="yellow-button">Meet Our Pricing Experts</button>
                        </a>
                    </div>
                </div>
            </section>

            <section className="main-edge-hotel hotel_main">
                <div className="container">
                    <div className="hotel_card_tab">
                        <TabContainer defaultActiveKey="mainfirst">
                            <Row id="h_card_one">
                                <Col sm={12} className="mb-3">
                                    <Nav variant="pills" className="r_main_pills" onSelect={handleSelect}>
                                        <Nav.Item>
                                            <Nav.Link eventKey="mainfirst">Hotels</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="mainsecond">Extended Stays</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="mainthird">Boutique Properties</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="mainfour">Budget Properties</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Col>
                                <Col sm={12}>
                                    <TabContent>
                                        <Tab.Pane eventKey="mainfirst">
                                            <Tab.Container>
                                                <>
                                                    <>
                                                        {selectedKey === 'mainfirst' &&
                                                            <>
                                                                <div className="d-flex justify-content-center mb-4">Revamp your hotel’s revenue game with Zettaprice: The leading AI solution for smarter pricing, greater profits, and smoother operations.</div>
                                                                <HotelCustomerCard
                                                                    details={hotels}
                                                                    isMobileScreen={isMobileScreen}
                                                                />
                                                            </>
                                                        }

                                                    </>

                                                </>
                                            </Tab.Container>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="mainsecond">
                                            <Tab.Container>
                                                {selectedKey === 'mainsecond'
                                                    &&
                                                    <>
                                                        <div className="d-flex justify-content-center mb-4">Unlock peak performance with Zettaprice: Your go-to AI solution for boosting revenue and streamlining operations in extended stays.</div>
                                                        <HotelCustomerCard
                                                            isMobileScreen={isMobileScreen}
                                                            details={extendedStays}
                                                        />
                                                    </>
                                                }

                                            </Tab.Container>
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="mainthird">
                                            <Tab.Container>
                                                {selectedKey === 'mainthird' &&
                                                    <>
                                                        <div className="d-flex justify-content-center mb-4">Elevate your boutique hotel with Zettaprice: Where cutting-edge AI meets exceptional guest experiences for unrivaled performance.</div>
                                                        <HotelCustomerCard
                                                            isMobileScreen={isMobileScreen}
                                                            details={boutique}
                                                        />
                                                    </>
                                                }

                                            </Tab.Container>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="mainfour">
                                            <Tab.Container>
                                                {selectedKey === 'mainfour' &&
                                                    <>
                                                        <div className="d-flex justify-content-center mb-4">Supercharge your budget hotel’s revenue with Zettaprice: The top choice for smart, efficient, and cost-effective management.</div>
                                                        <HotelCustomerCard
                                                            isMobileScreen={isMobileScreen}
                                                            details={budget}
                                                        />
                                                    </>
                                                }

                                            </Tab.Container>
                                        </Tab.Pane>

                                    </TabContent>
                                </Col>
                            </Row>
                        </TabContainer>
                    </div>
                </div>
            </section>

            <section className="qa_store hotel_why_us hotel_client_all">
                <div className="container" data-aos="new-animation">
                    <h2 className="bigHead text-center mb-5 bold">What all is in store for you ?</h2>
                    <div className="row">
                        <div className="col-md-4">
                            <InnerCard headImg={min_15} pageheading="15+ Minutes Price Update" pagelink={AllRoutes.hotel} />

                        </div>
                        {/* col-4 end */}
                        <div className="col-md-4">
                            <InnerCard headImg={revpar} pageheading="RevPAR Enhancement" pagelink={AllRoutes.hotel} />

                        </div>
                        {/* col-4 end */}
                        <div className="col-md-4">
                            <InnerCard headImg={comp_rev} pageheading="Comprehensive Revenue Maximisation" pagelink={AllRoutes.hotel} />

                        </div>
                    </div>
                </div>
            </section>


            <div className='mt-4 p-3 container py-5 d-flex flex-column align-items-center hotel-roadahead' data-aos="new-animation">
                <div className='w-100 d-flex justify-content-center text-center'>
                    <h4 className='bold'>Explore the Road Ahead: Tune into Our Podcast Series</h4>
                </div>
                <div className='mt-3 w-100 row'>
                    <div className='col-sm-12 col-md-6 p-2 order-md-2'>
                        <img className='w-100 corner' src={RAImg} alt={RATitle} />
                    </div>
                    <div className='mb-3 col-sm-12 col-md-6 p-sm-1 p-md-3 p-lg-5 d-flex flex-column justify-content-center align-items-center'>
                        <h2 className='text-center bold text-black'>{RATitle}</h2>
                        <a href={RAUrl} className='text-center p-2 mt-3 btn-black'>Know more</a>
                    </div>

                </div>
            </div>
            <section className="hotel-cta">
                <div className="container" data-aos="new-animation">
                    {/* <h2 className="bigHead text-center">
                        Explore the difference with Zettaprice
                    </h2> */}
                    <div className="text-center">
                        <a href={AllRoutes.hotelcontact}>
                            <button className='yellow-button'>Maximize Revenue now</button>
                        </a>
                    </div>
                </div>
            </section>

            <CapteraAchievements props={"hotel_inside"} />

            {
                caseStudies.length > 0 &&
                <section className="case_study">
                    <div className="container" data-aos="new-animation">
                        <h2 className="bigHead">The Proof is in the Results: Explore Sciative's Client Success Stories</h2>
                        <div className="row">
                            <div className="col-md-12 cs_1">
                                <Link to={`/research-articles/${caseStudies[0]['url']}`} target="_blank">
                                    <div className="card">
                                        {/* <div className="card_bg" style={{ backgroundImage: ` linear-gradient(rgb(0 0 0 / 65%), rgb(0 0 0 / 83%)), url(${caseStudies[0]['templateType'] === 'pdf' ? caseStudies[0]['image_key'] : caseStudies[0]['images']['img-main-img']['url']})` }}> */}
                                        <div className="card_bg" style={{ backgroundImage: ` linear-gradient(rgb(0 0 0 / 65%), rgb(0 0 0 / 83%)), url(${casebg})` }}>

                                            <div className="card_date">
                                                <p className="date">
                                                    {caseStudies[0]['inputs']['published']}
                                                </p>
                                                <div className="card_2">
                                                    {
                                                        // caseStudies[0]['templateType'] === 'pdf' ?
                                                        <a className="case_study_head bigHead text-white hover-text">
                                                            {caseStudies[0]['inputs']['blogTitle']}
                                                        </a>
                                                        // :
                                                        // <Link to={`/case-studies/${caseStudies[0]['url']}`}>
                                                        //     <a className="case_study_head bigHead text-white hover-text">
                                                        //         {caseStudies[0]['inputs']['blogTitle']}
                                                        //     </a>
                                                        // </Link>
                                                    }
                                                    <div className="case_det">
                                                        <span class="material-symbols-outlined">book_4</span>
                                                        <span className="read">
                                                            {caseStudies[0].hasOwnProperty('blogReadTime') && caseStudies[0]['blogReadTime']
                                                                ? `${caseStudies[0]['blogReadTime']} min read`
                                                                : '10 min read'}
                                                        </span>

                                                        {
                                                            caseStudies[0]['categories'].map((category, index) => {
                                                                return (
                                                                    <Link key={index} to={`/research-articles/category/${category}`} target="_blank">
                                                                        <span className="category hover-text">
                                                                            {category}
                                                                        </span>
                                                                    </Link>
                                                                )
                                                            })
                                                        }
                                                        {
                                                            caseStudies[0]['customCategories'].map((category, index) => {
                                                                return (
                                                                    <Link key={index} to={`/research-articles/category/${category}`} target="_blank">
                                                                        <span className="category">
                                                                            {category}
                                                                        </span>
                                                                    </Link>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </Link>
                            </div>

                            {
                                caseStudies.length > 1 &&
                                caseStudies.slice(1, 3).map((caseStudy, index) => {
                                    return (
                                        <div key={index} className="col-md-6 cs_2">
                                            <Link to={`/research-articles/${caseStudy['url']}`} target="_blank">
                                                <div className="card">
                                                    <div className="read">
                                                        {caseStudy.hasOwnProperty('blogReadTime') && caseStudy['blogReadTime'] ? `${caseStudy['blogReadTime']} min Read` : '10 min Read'}
                                                    </div>

                                                    <div className="card_cat d-flex gap-2">
                                                        {
                                                            caseStudy['categories'].map((category, index) => {
                                                                return (
                                                                    <Link key={index} to={`/research-articles/category/${category}`} target="_blank" className="cat">{category}</Link>
                                                                )
                                                            })
                                                        }
                                                        {
                                                            caseStudy['customCategories'].map((category, index) => {
                                                                return (
                                                                    <Link key={index} to={`/research-articles/category/${category}`} target="_blank" className="cat">{category}</Link>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                    {
                                                        // caseStudy['templateType'] === 'pdf' ?
                                                        // <div className="_">
                                                        //     <a onClick={() => handlePdfRequest(caseStudy['file_key'])} className="hover-text">{caseStudy['inputs']['blogTitle']}</a>
                                                        // </div>
                                                        // :
                                                        <div className="_">
                                                            <Link to={`/research-articles/${caseStudy['url']}`} target="_blank">
                                                                <a className="hover-text">{caseStudy['inputs']['blogTitle']}</a>
                                                            </Link>
                                                        </div>
                                                    }

                                                    <span className="date">{caseStudy['inputs']['published']}</span>
                                                </div>
                                            </Link>
                                        </div>
                                    )
                                })
                            }
                            {/* col-6 */}
                            <div className="col-md-12 text-center cs_3">
                                <p>Get access to our exclusive whitepapers and research reports to learn about what goes behind the science of pricing.</p>
                                <a href={AllRoutes.casestudies}>
                                    <Button className="yellow-button">Explore Sciative Insights<span class="material-symbols-outlined">chevron_right</span></Button>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
            }
            <HotelBgcta />
            <Recoginition page_name="home_recog" page_heading="Awards & Recognitions" />

            <section className="form-section">
                <div className="container" data-aos="new-animation">
                    <div className="text-container">
                        <h2 className="bigHead mb-5">Curious to know more about us!</h2>
                        <p>Explore more about how our pricing experts  can scale your revenue to soaring heights just by pricing right.</p>
                    </div>
                    <div className="form-container">
                        <Form {...formProps} />
                    </div>
                </div>
            </section>
        </HelmetProvider>
    );
}

export default HotelCustomer;
