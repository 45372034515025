import Footer from "../common/footer";
import Header from "../common/header";
import Ticker from "../common/ticker";
import { Accordion, Button } from 'react-bootstrap';
import AllRoutes from "../../AllRoutes";
import InnerCard from "../retail/innercard";
import axios from 'axios';
import img1 from "../../assets/img/retail/automated-pricing/1.png";
import img2 from "../../assets/img/retail/automated-pricing/2.png";
import img3 from "../../assets/img/retail/automated-pricing/3.png";
import cta_bg from '../../assets/img/retail/automated-pricing/bg_cta.png'
import shopify from '../../assets/img/partner/integration_2/shopify.png'
import amazon from '../../assets/img/partner/integration_2/amazon.png'
import flipkart from '../../assets/img/partner/integration_2/flipkart.png'
import clientimg from '../../assets/img/clients/rupesh-patil.png'
import kkimg from '../../assets/img/retail-client/kisan-konnect.png'
import img4 from "../../assets/img/retail/automated-pricing/4.png";
import img5 from "../../assets/img/retail/automated-pricing/5.png";
import img6 from "../../assets/img/retail/automated-pricing/6.png";
import img7 from "../../assets/img/retail/automated-pricing/7.png";
import img8 from "../../assets/img/retail/automated-pricing/8.png";
import img9 from "../../assets/img/retail/automated-pricing/9.png";
import min_15 from '../../assets/img/hotels/slider_image/1-img.jpg';
import revpar from '../../assets/img/hotels/slider_image/2-img.jpg';
import comp_rev from '../../assets/img/hotels/slider_image/3-img.jpg';
import HotelHeader from "../hotels/hotelHeader";
import HotelHeaderMobile from "../hotels/hotelHeaderMobile";
import bggoal from "../../assets/img/store/goal-driven-pricing.png";
import bgunified from "../../assets/img/store/unified-pricing.png";
import bgcomp from "../../assets/img/store/competition-intelligence.png";
import bgautomated from "../../assets/img/store/automated-pricing.png";

import img1_ from "../../assets/img/retail/pricing-consulting/navigate_complex_web_of_pricing.png";
import img2_ from "../../assets/img/retail/pricing-consulting/minimise_churn_rate.png";
import img3_ from "../../assets/img/retail/pricing-consulting/benchmark_your_brand.png";
import img4_ from "../../assets/img/retail/pricing-consulting/shorter_gestation_period_and_lower_cost.png";
import AOS from "aos";
import { useEffect, useState } from "react";
import "aos/dist/aos.css";

import { Tab, Col, Nav, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Recoginition from "../retail/recognition";
import CapteraAchievements from "../travel/capterra-achievvement";
import RetailHeader from "../retail/retailHeader";
import RetailClient from "../retail/retailCLient";

import QAScore from "../retail/QAScore";
import InnerHeroRetail from "../retail/innerHeroRetail";
import RetailInsideNumber from "../retail/retailInsideNumber";
import RetailBenefits from "../retail/retailBenefits";
import MarketPlaceIntegration from "../retail/marketPlaceIntegration";
import RetailTestimonial from "../retail/retailTestimonial";
import HotelFaqSection from "../hotels/hotelFaqSection";
import HotelBgcta from "../hotels/hotelbgcta";
import Form from "../Forms/Form";
import { toast } from "react-toastify";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { GlobalUrl, GlobalImg } from "../../global";
import NavigationSection from "../retail/NavigationSection";
import BenefitSection from "../retail/BenefitSection";
import b1 from "../../assets/img/icon/Benchmarking.png";
import b2 from "../../assets/img/icon/assessment.png";
import b3 from "../../assets/img/icon/Optimization.png";
import b4 from "../../assets/img/icon/uncertainty.png";
import b5 from "../../assets/img/icon/AItechnology.png";
import b6 from "../../assets/img/icon/Support.png";
import casebg from "../../assets/img/home/casee-bg.jpg";
import Modal from 'react-bootstrap/Modal';
import CloseIcon from '@mui/icons-material/Close';

const HotelFaq = ({ }) => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, [])
  const [RATitle, setRATitle] = useState('');
  const [RAUrl, setRAUrl] = useState('');
  const [RAImg, setRAImg] = useState('');

  const [showHeader1, setShowHeader1] = useState(false);
  const [showHeader2, setShowHeader2] = useState(false);
  const end_point = "hotelfaq";
  function scrollFunction() {
    // console.log("here")
    var winScroll = document.body.scrollTop || document.documentElement.scrollTop;
    if (winScroll >= 80 && window.innerWidth >= 600) {
      setShowHeader2(false);
      setShowHeader1(true);
      // console.log("in if")
    }
    else if (winScroll >= 80 && window.innerWidth < 600) {
      setShowHeader1(false)
      setShowHeader2(true)
      // console.log("in elif")
    }
    else {
      setShowHeader1(false)
      setShowHeader2(false)
      // console.log("in else")
    }
  }

  useEffect(() => {
    document.addEventListener('wheel', function () { scrollFunction() });
    document.addEventListener('scroll', function () { scrollFunction() });
    // window.addEventListener('wheel', function () { scrollFunction() });
    // window.addEventListener('scroll', function () { scrollFunction() });
  }, [])
  const formProps = {
    template: 4,
    form_name: 'hotel-main-page',
    fields: [
      { name: 'name', required: true },
      { name: 'email', required: true },
      { name: 'company', required: true },
      { name: 'phone', required: true },
      { name: 'message', required: true },
    ],
    recipients_group_id: 5,
    handleApiResponse: (res) => {
      toast.dismiss();
      if (res.status == "success") {
        toast.success('Form submitted successfully! We will get back to you in 2-3 days.');
      } else {
        toast.error(res.data)
      }
    }
  }

  const faqprops = {

    faq: [
      {
        title: "What is dynamic pricing?",
        desc: "Dynamic pricing is a strategy where the price of a product or service is adjusted in real-time based on market demand, competition, and other external factors."
      },
      {
        title: "How can dynamic pricing benefit my hotel?",
        desc: "Dynamic pricing can help maximize revenue by ensuring room rates are competitive and optimized for demand, reduce manual pricing efforts, and provide valuable market insights."
      },
      {
        title: "Does your revenue management software (RMS) integrate with my existing Property Management System (PMS)?",
        desc: "Yes, our software integrates with most major PMSs, ensuring seamless data flow and automated pricing updates."
      },
      {
        title: "How long does it take to implement your dynamic pricing software?",
        desc: "Implementation typically takes 2 days, depending on the complexity of your operations and integration requirements."
      },
      {
        title: "What kind of support and training do you offer?",
        desc: "We offer comprehensive onboarding, including training sessions, documentation, and 24/7 customer support to ensure you get the most out of our software."
      },
      {
        title: "How do you ensure the security of my data?",
        desc: "We use industry-standard encryption and security protocols to protect your data. Our systems are regularly audited to ensure compliance with data protection regulations."
      },
      {
        title: "Can I set my own pricing rules and constraints?",
        desc: "Yes, our software allows you to set custom pricing rules, minimum and maximum rates, and other constraints to align with your pricing strategy and policies."
      },
      {
        title: "Can the software handle different pricing strategies for different segments (e.g., corporate, leisure)?",
        desc: "Yes, our software can manage and optimize pricing for different market segments, ensuring tailored pricing strategies for each."
      },
      {
        title: "How soon can I expect to see results?",
        desc: "Many of our clients see noticeable improvements in revenue and occupancy within the first few months of using our software, though results can vary."
      },
      {
        title: "Do you provide analytics and reporting on the performance of the dynamic pricing strategy?",
        desc: "Yes, we provide detailed reports and dashboards that offer insights into pricing performance, market trends, and overall revenue impact."
      },
      {
        title: "Do you offer a trial period or a demo?",
        desc: "Yes, we offer a demo and a trial period so you can experience the benefits of our software before making a commitment."
      },
      {
        title: "How does your software handle last-minute bookings and cancellations?",
        desc: "Our software continuously updates prices based on real-time demand and availability, ensuring optimal pricing even for last-minute changes."
      },
      {
        title: "Can we manually update price after activating revenue management software?",
        desc: "Generally yes,  manual changes can be done even after opting for automated pricing adjustments."
      },
      {
        title: "Is it possible to set additional surcharge for certain date on certain season ?",
        desc: "Yes, you can set surcharges for specific dates or seasons using our software."
      },


    ]

  }
  const getRecentRATalk = async () => {
    try {
      const response = await axios.get(`/ra_talks/fetch_all_webinars`, {

        headers: {
          'Content-Type': 'application/json'
        },
      });
      if (response.status === 200 && response.data !== undefined) {
        let raData = response.data.find(item => item['category'] === "hospitality");
        if (raData) {
          setRATitle(raData.title);
          setRAUrl('/road-ahead-talks/' + raData.seo.pageURl);
          setRAImg(raData['pre-img']['pre-img-key']);
        }
      }
    } catch (err) {
      console.error("Error", err);
    }
  }
  const [caseStudies, setCaseStudies] = useState([]);

  const fetch_all_case_studies = async () => {
    try {
      const response = await axios.post(`/fetch_blogs_by_category`, {
        category: "Hospitality"
      }, {
        headers: {
          'Content-Type': 'application/json'
        },

      });
      if (response.status == 200 && response.data !== undefined) {
        setCaseStudies(response.data);
      }
    } catch (err) {

    }
  }
  const [pdfRequestModal, setPdfRequestModal] = useState(false);
  const [requestedFileKey, setRequestedFileKey] = useState('');

  const handlePdfRequest = (file_key) => {
    setPdfRequestModal(true);
    setRequestedFileKey(file_key);
  }
  const pdfRequestProps = {
    template: 1,
    form_name: "case-studies-pdf-request",
    fields: [
      { name: "name", required: true },
      { name: "email", required: true },
      { name: "phone", required: true }
    ],
    title: "See Sciative in Action & Drive Results",
    content_upper: "Make data-driven decisions with confidence. Dive into case studies and see how Sciative tackled challenges and delivered exceptional results. Get access to case studies and unlock exceptional insights that inspire you.",
    content_lower: "",
    recipients_group_id: 1,
    requestedFileKey: requestedFileKey,
    handleApiResponse: (res) => {
      toast.dismiss();
      if (res && res.status && res.status === "success") {
        window.open(res.data.signed_url);
        toast.success('Form submitted successfully!');
        setPdfRequestModal(false);
      }
      else {
        toast.error(res.data);
      }
    }
  }
  useEffect(() => {
    fetch_all_case_studies();
    getRecentRATalk()
  }, [])

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Get all your Answers for Hotel Revenue Management | Sciative</title>
          <link rel="canonical" href={`${GlobalUrl}${AllRoutes.hotelcontact}`} />
          <meta charset="utf-8" />
          <meta http-equiv="X-UA-Compatible" content="IE=edge" />
          <meta name="csrf_token" content="" />
          <meta property="type" content="website" />
          <meta name="theme-color" content="#ffffff" />
          <meta name="_token" content="" />
          <meta name="robots" content="noodp" />
          <meta name='description' content="Find answers to your questions about the Revenue Management System. Our FAQ page covers features, setup, and more for seamless integration and support" />
          <meta property="og:locale" content="en_US" />
          <meta property="og:title" content="Get all your Answers for Hotel Revenue Management | Sciative" />
          <meta property="og:description" content="Find answers to your questions about the Revenue Management System. Our FAQ page covers features, setup, and more for seamless integration and support" />
          <meta property="og:hashtag" content="#ai" />
          <meta content="image/*" property="og:image:type" />
          <meta property="og:url" content={`${GlobalUrl}${AllRoutes.hotelcontact}`} />
          <meta property="og:site_name" content="Sciative Solutions - We Price Right" />
          <meta property="og:image" content={GlobalImg} />
          <meta property="og:type" content="website" />

        </Helmet>
        {
          showHeader1 ?
            <section style={{ padding: '0', borderBottom: '1px solid #fff', width: '100%', position: 'fixed', top: '43px', height: '4.5em', zIndex: 100 }} className='banner_retail main_banner bg-black'>
              <div className='container'>
                <HotelHeader end_point={end_point} />
              </div>
            </section>
            :
            showHeader2 ?
              <HotelHeaderMobile />
              :
              null
        }
        <section className="smallBanner hotel_faq">
          <div className="container"  >
            <HotelHeader end_point={end_point} />
            <p className="small_head">Pricing FAQs</p>
            <h1 className="about_para">Have your pricing questions <br /> answered here.</h1>
            <p className="about_para_2">
              Learn why Zettaprice is a leading choice in hotel revenue management by exploring our FAQs.
            </p>

            <div>
              {/* <Button className="transparent-button">Discover more! </Button> */}
              <Link to={AllRoutes.hotelcontact}>
                <button className="yellow-button">Invest in Automated AI Tech Company</button>
              </Link>
            </div>



          </div>
        </section>


        <HotelFaqSection   {...faqprops} />
        <div className='mt-4 p-3 container py-5 d-flex flex-column align-items-center hotel-roadahead hotel_client_all hotel_why_us' data-aos="new-animation">
          <div className='w-100 d-flex justify-content-center text-center'>
            <h4 className='bold'>Explore the Road Ahead: Tune into Our Podcast Series</h4>
          </div>
          <div className='mt-3 w-100 row'>
            <div className='col-sm-12 col-md-6 p-2 order-md-2'>
              <img className='w-100 corner' src={RAImg} alt={RATitle} />
            </div>
            <div className='mb-3 col-sm-12 col-md-6 p-sm-1 p-md-3 p-lg-5 d-flex flex-column justify-content-center align-items-center'>
              <h2 className='text-center bold text-black'>{RATitle}</h2>
              <a href={RAUrl} className='text-center p-2 mt-3 btn-black'>Know more</a>
            </div>

          </div>
        </div>
        <section className="hotel-cta">
          <div className="container" data-aos="new-animation">
            {/* <h2 className="bigHead text-center">
              Explore the difference with Zettaprice
            </h2> */}
            <div className="text-center">
              <Link to={AllRoutes.hotelcontact}>
                <button className='yellow-button'>Maximize Revenue now</button>
              </Link>
            </div>
          </div>
        </section>


        <CapteraAchievements props={"hotel_inside"} />

        {
          caseStudies.length > 0 &&
          <section className="case_study">
            <div className="container" data-aos="new-animation">
              <h2 className="bigHead">The Proof Is in the Results: Explore Sciative's Client Success Stories</h2>
              <div className="row">
                <div className="col-md-12 cs_1">
                  <Link to={`/research-articles/${caseStudies[0]['url']}`} target="_blank">
                    <div className="card">
                      {/* <div className="card_bg" style={{ backgroundImage: ` linear-gradient(rgb(0 0 0 / 65%), rgb(0 0 0 / 83%)), url(${caseStudies[0]['templateType'] === 'pdf' ? caseStudies[0]['image_key'] : caseStudies[0]['images']['img-main-img']['url']})` }}> */}
                      <div className="card_bg" style={{ backgroundImage: ` linear-gradient(rgb(0 0 0 / 65%), rgb(0 0 0 / 83%)), url(${casebg})` }}>

                        <div className="card_date">
                          <p className="date">
                            {caseStudies[0]['inputs']['published']}
                          </p>
                          <div className="card_2">
                            {
                              // caseStudies[0]['templateType'] === 'pdf' ?
                              <a className="case_study_head bigHead text-white hover-text">
                                {caseStudies[0]['inputs']['blogTitle']}
                              </a>
                              // :
                              // <Link to={`/case-studies/${caseStudies[0]['url']}`}>
                              //     <a className="case_study_head bigHead text-white hover-text">
                              //         {caseStudies[0]['inputs']['blogTitle']}
                              //     </a>
                              // </Link>
                            }
                            <div className="case_det">
                              <span class="material-symbols-outlined">book_4</span>
                              <span className="read">
                                {caseStudies[0].hasOwnProperty('blogReadTime') && caseStudies[0]['blogReadTime']
                                  ? `${caseStudies[0]['blogReadTime']} min read`
                                  : '10 min read'}
                              </span>
                              {
                                caseStudies[0]['categories'].map((category, index) => {
                                  return (
                                    <Link key={index} to={`/research-articles/category/${category}`} target="_blank">
                                      <span className="category hover-text">
                                        {category}
                                      </span>
                                    </Link>
                                  )
                                })
                              }
                              {
                                caseStudies[0]['customCategories'].map((category, index) => {
                                  return (
                                    <Link key={index} to={`/research-articles/category/${category}`} target="_blank">
                                      <span className="category">
                                        {category}
                                      </span>
                                    </Link>
                                  )
                                })
                              }
                            </div>
                          </div>
                        </div>


                      </div>
                    </div>
                  </Link>
                </div>

                {
                  caseStudies.length > 1 &&
                  caseStudies.slice(1, 3).map((caseStudy, index) => {
                    return (
                      <div key={index} className="col-md-6 cs_2">
                        <Link to={`/research-articles/${caseStudy['url']}`} target="_blank">
                          <div className="card">
                            <div className="read">  {caseStudy.hasOwnProperty('blogReadTime') && caseStudy['blogReadTime'] ? `${caseStudy['blogReadTime']} min Read` : '10 min Read'}</div>
                            <div className="card_cat d-flex gap-2">
                              {
                                caseStudy['categories'].map((category, index) => {
                                  return (
                                    <Link key={index} to={`/research-articles/category/${category}`} target="_blank" className="cat">{category}</Link>
                                  )
                                })
                              }
                              {
                                caseStudy['customCategories'].map((category, index) => {
                                  return (
                                    <Link key={index} to={`/research-articles/category/${category}`} target="_blank" className="cat">{category}</Link>
                                  )
                                })
                              }
                            </div>
                            {
                              // caseStudy['templateType'] === 'pdf' ?
                              // <div className="_">
                              //     <a onClick={() => handlePdfRequest(caseStudy['file_key'])} className="hover-text">{caseStudy['inputs']['blogTitle']}</a>
                              // </div>
                              // :
                              <div className="_">
                                <Link to={`/research-articles/${caseStudy['url']}`} target="_blank">
                                  <a className="hover-text">{caseStudy['inputs']['blogTitle']}</a>
                                </Link>
                              </div>
                            }

                            <span className="date">{caseStudy['inputs']['published']}</span>
                          </div>
                        </Link>
                      </div>
                    )
                  })
                }
                {/* col-6 */}
                <div className="col-md-12 text-center cs_3">
                  <p>Get access to our exclusive whitepapers and research reports to learn about what goes behind the science of pricing.</p>
                  <Link to={AllRoutes.casestudies}>
                    <Button className="yellow-button">Explore Sciative Insights<span class="material-symbols-outlined">chevron_right</span></Button>
                  </Link>
                </div>
              </div>
            </div>
          </section>
        }
        <HotelBgcta />
        <Recoginition page_name="home_recog" page_heading="Awards & Recognitions" />

        <section className="qa_store">
          <div className="container" data-aos="new-animation">
            <h2 className="bigHead text-center mb-5">What all is in store for you ?</h2>
            <div className="row">
              <div className="col-md-4">
                <InnerCard headImg={min_15} pageheading="15+ Minutes Price Update" pagelink={AllRoutes.hotel} />

              </div>
              {/* col-4 end */}
              <div className="col-md-4">
                <InnerCard headImg={revpar} pageheading="RevPAR Enhancement" pagelink={AllRoutes.hotel} />

              </div>
              {/* col-4 end */}
              <div className="col-md-4">
                <InnerCard headImg={comp_rev} pageheading="Comprehensive Revenue Maximisation" pagelink={AllRoutes.hotel} />

              </div>
            </div>
          </div>
        </section>

        <section className="form-section">
          <div className="container" data-aos="new-animation">
            <div className="text-container">
              <h2 className="bigHead mb-5">Curious to know more about us!</h2>
              <p>Explore more about how our pricing experts  can scale your revenue to soaring heights just by pricing right.</p>
            </div>
            <div className="form-container">
              <Form {...formProps} />
            </div>
          </div>
        </section>
      </HelmetProvider>
      <Modal
        show={pdfRequestModal}
        onHide={() => setPdfRequestModal(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        contentClassName="remove-white-bg"
        backdrop="static"
        centered
      >
        <Modal.Body className="p-0">
          <CloseIcon onClick={() => setPdfRequestModal(false)} className='modal-close-icon' />
          <Form {...pdfRequestProps} />
        </Modal.Body>
      </Modal>
    </>
  )
}
export default HotelFaq;