import { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { ProgressBar } from '@nadfri/react-scroll-progress-bar';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import {Button, TextField, IconButton, Box } from '@mui/material';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { fetchToken } from '../../Auth';
import { toast } from 'react-toastify';
import axios from 'axios';
import copy from 'copy-to-clipboard';
import RecommendedCaseStudies from './RecommendedCaseStudies';
import Contents from './Contents';
import Form from '../../components/Forms/Form';
import Modal from 'react-bootstrap/Modal';
import CloseIcon from '@mui/icons-material/Close';
import chats from '../../assets/img/chats.png'
import notes from '../../assets/img/notes.png'
import share from '../../assets/img/share.png'
import print from '../../assets/img/print.png'

import instagram from '../../assets/img/instagram.png'
import facebook from '../../assets/img/facebook.png'
import youtube from '../../assets/img/youtube.png'
import linkedin from '../../assets/img/linkedin.png'
import x from '../../assets/img/x.png'

import download from '../../assets/img/arrow_downward.png'
import DOMPurify from "dompurify";
import '../../assets/css/blogs.css'

import { GlobalUrl, GlobalImg } from '../../global';
import Contributor from '../../components/common/contributor';
import parse from "html-react-parser";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import {
    FacebookShareButton,
    WhatsappShareButton,
    WhatsappIcon,
    FacebookIcon,
    LinkedinShareButton,
    LinkedinIcon,
    TwitterShareButton,
    TwitterIcon,
    EmailShareButton,
    EmailIcon
} from 'react-share';

import '../../assets/css/caseStudy.css'

const CaseStudyPage = () => {

    const [caseStudyContent,setCaseStudyContent] = useState("")
    const [formFilled,setFormFilled] = useState(false)
    const [caseStudyRead,setCaseStudyRead] = useState(false)
    const [isAdditionalContentVisible, setIsAdditionalContentVisible] = useState(formFilled);

    const [shareOpen,setShareOpen] = useState(false)

    const [linkToShare,setLinkToShare] = useState("")
    const [dFile,setDFile] = useState(false)

    const toggleAdditionalContent = () => {
        setDFile(false)
        handlePdfRequest(caseStudy,false)
    //   setIsAdditionalContentVisible(!isAdditionalContentVisible);
    };

    const shareCaseStudies = () =>{
        setShareOpen(true)
    }

    const [copied, setCopied] = useState(false);

    const handleCopyLink = () => {
        if (copy(linkToShare)) {
            setCopied(true);
            setTimeout(() => setCopied(false), 2000);
        } else {
            alert('Failed to copy the link.');
        }
    };
    

    
    const onClose = () =>{
        setShareOpen(false)
    }
    const handleShare = (platform) => {
        let shareUrl = '';

        const encodedUrl = encodeURIComponent(linkToShare);
        const title = encodeURIComponent('Case Study Title : ' + caseStudy['title']);
        const text = encodeURIComponent('Checkout this Amazing Case Study');
        const caseStudyLink = encodeURIComponent('Link: ' + linkToShare);
         // Example of a custom link if needed
    
        switch (platform) {
            case 'whatsappweb':
                shareUrl = `https://web.whatsapp.com/send?text=${title}%0A${text}%0A${caseStudyLink}`;
                break;
            case 'facebook':
                shareUrl = `https://www.facebook.com/dialog/share?app_id=YOUR_APP_ID&display=popup&href=${encodedUrl}&quote=${title}%0A${text}%0A${caseStudyLink}`;
                break;
            // case 'linkedin':
                // shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}&title=${title}`;
                // break;
            case 'linkedin':
                shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${linkToShare}`;
                break;
            case 'twitter':
                shareUrl = `https://twitter.com/intent/tweet?text=${title}%0A${text}%0A${caseStudyLink}`;
                break;
            default:
                return;
        }

        window.open(shareUrl, '_blank');
    };
    

    const getCaseStudyReadStatus = async () =>{
        let storedData =JSON.parse(localStorage.getItem('form_data'));
        if(storedData){
            let email = storedData['data']['email']
            let page = window.location.pathname + window.location.search
            const postData = {
                email:email,
                page:page
            }
            try {
                const response = await axios.post(`/validate_case_study`, postData, {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                });
                if (response.data !== undefined) {
                    if(response.data.success == true){
                        setCaseStudyRead(true)
                    }
                    else{
                        setCaseStudyRead(false)
                    }
    
                }      
            } catch (error) {
                console.log(error)
            }

        }
        else{
            setCaseStudyRead(false)
        }


    }


    

    const downloadFile = async () =>{
        let storedData =JSON.parse(localStorage.getItem('form_data'));
        if(storedData){
            setFormFilled(true)
        }
        let name = storedData['data']['name']
        let email = storedData['data']['email']
        let phone = storedData['data']['phone']
        let phonein = storedData['data']['phonein']
        let company = storedData['data']['company']
        let topic = storedData['data']['topic']
        let msg = storedData['data']['msg']

        let requestedFileKey = caseStudy['file_key']
        const postData = {
            template: 1,
            recipients_group_id:1,
            form_name: "case-studies-pdf-request",
            fields: [
                { name: "name", required: true },
                { name: "email", required: true },
                { name: "phone", required: true },
                { name : "caseStudyTitle",required:true},
                { name : "categories",required:true}
            ],
            data: {
                name: DOMPurify.sanitize(name),
                email: DOMPurify.sanitize(email),
                phone: DOMPurify.sanitize(phone),
                phonein: DOMPurify.sanitize(phonein),
                company: DOMPurify.sanitize(company),
                topic: DOMPurify.sanitize(topic),
                message: DOMPurify.sanitize(msg),
                source: "",
                destination: "",
                categories: categories, 
                caseStudyTitle: caseStudyTitle,
                caseStudyDownload:true,
            },

            page: window.location.pathname + window.location.search,
            requestedFileKey: requestedFileKey
        }
        try {
            const response = await axios.post(`/submit_form`, postData, {
                headers: {
                    'Content-Type': 'application/json'
                },
                responseType: ''
            });
            if (response.status === 200 && response.data !== undefined) {
                if (response && response.status && response.status === 200) {
                    // window.open(response.data.signed_url);
                    const link = document.createElement('a');
                    link.href = response.data.signed_url; 
                    link.download = ''; 
                    link.click();

                    setPdfRequestModal(false);
                    setCaseStudyRead(true)
                } else {
                    toast.error(response.data);
                }

            }      
        } catch (error) {

            if (error.response && error.response.data) {
            }
            else {
            }
        }

    }
    const downloadPdf = () =>{
        let download = true
        if(formFilled && caseStudyRead){
            downloadFile()
        }
        else{
            setDFile(true)
            handlePdfRequest(caseStudy,download)
        }
    }


    const [url, setUrl] = useState('');
    const [caseStudy, setCaseStudy] = useState(null);
    const [template, setTemplate] = useState(null);

    const headingId = useRef(0);
    const [headings, setHeadings] = useState([]);
    const [currentHeading, setCurrentHeading] = useState('');

    const [formModal, setFormModal] = useState(false);

    // Case Study Page
    const [pdfRequestModal, setPdfRequestModal] = useState(false);
    const [requestedFileKey, setRequestedFileKey] = useState('');
    const [caseStudyTitle,setCaseStudyTitle] = useState('')
    const [categories,setCategories] = useState('')

    const handlePdfRequest = (blog) => {
        setPdfRequestModal(true);
        setCaseStudyTitle(blog['title'])
        setCategories(blog['categories'].join(','));
        setRequestedFileKey(blog['file_key']);
    };

    const pdfRequestProps = {
        template: 1,
        form_name: dFile ? "case-studies-pdf-request": "case-studies-read-request",
        fields: [
            { name: "name", required: true },
            { name: "email", required: true },
            { name: "phone", required: true },
            { name : "caseStudyTitle",required:true},
            { name : "categories",required:true}
        ],
        title: "See Sciative in Action & Drive Results",
        // content_upper: "Make data-driven decisions with confidence. Dive into case studies and see how Sciative tackled challenges and delivered exceptional results. Get access to case studies and unlock exceptional insights that inspire you.",
        content_upper:"To gain access to the complete insights of this case study, please fill out the form below. Your information will help us provide personalized content and updates tailored to your interests.",
        content_lower: "",
        recipients_group_id: 1,
        requestedFileKey: requestedFileKey,
        recipients_group_id: 1,
        caseStudyTitle:caseStudyTitle,
        categories:categories,
        caseStudyDownload:dFile,
        handleApiResponse: (res) => {
            toast.dismiss();
            if (res && res.status && res.status === "success") {
                if(dFile){
                    const link = document.createElement('a');
                    link.href = res.data.signed_url; 
                    link.download = ''; 
                    link.click();
                }
                // toast.success('Form submitted successfully!');
                setIsAdditionalContentVisible(true)
                setFormFilled(true)
                setPdfRequestModal(false);
                setCaseStudyRead(true)
            } else {
                toast.error(res.data);
            }
        }
    };

    const [props, setProps] = useState({
        template: 1,
        form_name: "case-study-page", //
        fields: ["name", "email"],
        title: "",
        content_upper: "upper content",
        content_lower: "lower content",
        recipients_group_id: 1,
        handleApiResponse: (res) => {
            toast.dismiss();
            if (res.status == "success"){
                toast.success('Form Submitted successfully')
              } else {
                toast.error(res.data)
              }            
              setFormModal(false);
        }
    })

    useEffect(() => {
    if(isAdditionalContentVisible){
        populateCaseStudy(true)
    }
    }, [isAdditionalContentVisible])
    
    const subscribeProps = {
        template: 2,
        text_color: 'text-black',
        form_name: "case-study-page", //
        fields: [{
            name: "email",
            required: true
        }],
        recipients_group_id: 1,
        handleApiResponse: (res) => {
            toast.dismiss();
            console.log(res)
            if (res.status == "success"){
                toast.success('Form submitted successfully!');
              } else {
                toast.error(res.data)
              }
        }
    }

    const guestSpeakerProps = {
        template: 1,
        form_name: "become-a-guest-speaker",
        fields: [
            { name: "name", required: true },
            { name: "email", required: true },
            { name: "company", required: true },
            { name: "topic", required: true }
        ],
        title: "Become Our Guest Speaker",
        content_upper: "Got an amazing story to share? Become a featured guest on our podcast and share your insights with our audience. Submit your topic idea and let's make magic together on air.",
        content_lower: "Your voice could reach thousands of eager listeners!",
        recipients_group_id: 1,
        handleApiResponse: (res) => {
            toast.dismiss();
            if (res && res.status && res.status === "success") {
                setGuestModal(false);
                toast.success('Form submitted successfully!');
            }
            else {
                toast.error(res.data);
            }
        }
    }


    const guestWriterProps = {
        template: 1,
        form_name: "case-studies-become-a-guest-writer",
        fields: [
            { name: "name", required: true },
            { name: "email", required: true },
            { name: "company", required: true },
            { name: "topic", required: true }
        ],
        title: "Become a Guest Writer",
        content_upper: "Got a great idea? We want to hear it! Submit your case study topic here and become a valued contributor to our site.",
        content_lower: "Your insights could reach thousands!",
        recipients_group_id: 1,
        handleApiResponse: (res) => {
            toast.dismiss();
            setGuestModal(false);
            if (res.status == "success"){
                toast.success('Form submitted successfully!');
            } else {
                toast.error(res.data)
            }
        }
    }

    const [guestModal, setGuestModal] = useState(false);
    const [writerModal, setWriterModal] = useState(false);

    const { caseStudyId } = useParams();
    const navigate = useNavigate();

    const getCaseStudy = async (url) => {
        try {
            const response = await axios.get(`/get_case_study/${url}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${fetchToken('nuprice_access_token')}`,
                },
            });
            if (response.status == 200 && response.data !== undefined) {
                setCaseStudy(response.data)
                setCaseStudyTitle(response.data['title'])
                setCategories(response.data['categories'].join(','))
                getTemplate(response.data.templateId);
                setRequestedFileKey(response.data['file_key'])
            }
        } catch (err) {
            console.error("Error", err);
            if (err.response.status === 400) {
                navigate('/error')
            }
            if (err.response.status === 401) {
                navigate('/login')
            }
        }
    }

    const getTemplate = async (templateId) => {
        try {
            const response = await axios.get(`/get_case_study_template/${templateId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${fetchToken('nuprice_access_token')}`,
                },
            });
            if (response.status == 200 && response.data !== undefined) {
                setTemplate(response.data)
            }
        } catch (err) {
            console.error("Error", err);
            if (err.response.status === 401) {
                navigate('/login')
            }
        }
    }

    useEffect(() => {
        if (caseStudyId && !caseStudy) {
            setUrl(caseStudyId);
            getCaseStudy(caseStudyId);
            setLinkToShare(window.location.href)
        }
    }, [caseStudyId])

    useEffect(() => {
        if (template && caseStudy) {
            populateCaseStudy()
        }
    }, [template, caseStudy])

    let options = {
        root: document.querySelector("#scrollArea"),
        rootMargin: '-50% 0% -50% 0%',
        threshold: 0,
    };

    // const intersectionCallback = (entries) => {
    //     console.log(entries,'this is the entries')
    //     entries.forEach((entry) => {
    //         if (entry.isIntersecting) {
    //             Array.from(document.querySelector('.toc-nav').querySelectorAll('li')).forEach(h => h.classList.remove('bold'))
    //             document.getElementById(`heading-${entry.target.id}`).classList.add('bold')
    //             setCurrentHeading(entry.target.innerText)
    //         }
    //     });
    // };

    // let observer = new IntersectionObserver(intersectionCallback, options);

    const showForm = (formName) => {
        let temp = props
        temp['form_name'] = formName
        setProps(temp);
        setFormModal(true)
    }

    const populateCaseStudy = (status) => {
        document.getElementById('case-study-section').innerHTML = ""
        JSONtoHTML(template['structure'], document.getElementById('case-study-section'))
        let content = caseStudy['additionalContent']
        setCaseStudyContent(content)
        let arr = content.split('##')
        for (let i = 0; i < arr.length; i += 1) {
            if (arr[i].includes('__')) {
                arr[i] = `<a target='_blank' href='//${(arr[i].split('__'))[1]}'>${(arr[i].split('__'))[0]}</a>`
            }
        }
        content = arr.join('')

        let visibleContent = caseStudy['visibleContent']
        let visibleArr = visibleContent.split('##')
        for (let i = 0; i < visibleArr.length; i += 1) {
            if (visibleArr[i].includes('__')) {
                visibleArr[i] = `<a target='_blank' href='//${(visibleArr[i].split('__'))[1]}'>${(visibleArr[i].split('__'))[0]}</a>`
            }
        }
        // document.getElementById('additional-content').innerHTML = content
        // setCaseStudyContent(content)
        document.getElementById('visible-container').innerHTML = visibleContent

        //Extract form buttons and attach onclick function to them to show forms
        let buttons = Array.from(document.getElementById('casestudy-content').querySelectorAll('.clickable-form-button'))
        buttons.forEach(btn => {
            btn.addEventListener('click', () => showForm(btn.getAttribute('data-attached-form')))
        })

        //Set Headings
        // let elements = Array.from(document.querySelector('#additional-content').querySelectorAll("h2, h3, h4, h5"))
        // for (let i = 0; i < elements.length; i += 1) {
        //     if (elements[i].innerText.trim() !== '') {
        //         observer.observe(elements[i]);
        //     }
        //     elements[i].setAttribute('id', i)
        // }
        // elements = elements
        //     .filter((elem) => elem.innerText.trim() !== '')
        //     .map((elem) => ({
        //         id: elem.getAttribute('id'),
        //         text: elem.innerText,
        //         level: Number(elem.nodeName.charAt(1))
        //     }))
        // setHeadings(elements)



        for (let key in caseStudy['images']) {
            let name = key
            let type = caseStudy['images'][key]['type'].split('/')[1]
            let layout = ''
            if(formFilled && isAdditionalContentVisible){
                if (name !== 'img-main-img' ) {
                    layout = caseStudy['images'][key]['layout']
                }
                if(status && name == 'img-main-img'){
                    continue
                }
                populateImages(name, type, layout)
            }
            else{
                if(name == "img-main-img"){
                    populateImages(name, type, layout)
                }
            }

        }
        if(formFilled && caseStudyRead){
            setIsAdditionalContentVisible(true)
        }
    }

    async function populateImages(name, type, layout) {
        try {
            const response = await fetch(`/get_case_study_image/${url + '_' + name}.${type}`)
            const data = await response.json();
            if (name === 'img-main-img') {
                //Render featured image
                const styleTag = document.createElement('style');
                const cssRules = `
                    .blog-title-section::after {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-image: url('${data}');
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: cover;
                        mix-blend-mode: multiply;
                        /* to blend the overlay with the image */
                    }
                `;
                styleTag.innerHTML = cssRules;
                document.head.appendChild(styleTag);

                const meta = document.createElement('meta');
                meta.setAttribute("property", "og:image")
                meta.setAttribute("content", data)
                document.head.appendChild(meta);

            } else {
                try {
                    document.getElementById(name).setAttribute('src', data)
                    document.getElementById(name).classList.add(`blog-img-${layout}`)
                    document.getElementById(name).classList.add('my-3')
                } catch (error) {
                    console.error(error)
                }

            }

            // .then(blob => {
            //     // Convert the blob to a URL
            //     const url = URL.createObjectURL(blob);

            //     if (name === 'img-main-img') {

            //     } else {
            //         document.getElementById(name).setAttribute('src', url)
            //         document.getElementById(name).classList.add(`blog-img-${layout}`)
            //         document.getElementById(name).classList.add('my-3')
            //     }

            // })
        } catch (err) {
            console.error("Error", err);
            if (err.response.status === 401) {
                navigate('/login')
            }
        }
    }

    function JSONtoHTML(json, parentElement) {
        let element = document.createElement(json.tagName);
        if ('className' in json) {
            let classes = json.className.split(' ')
            classes.forEach(cl => element.classList.add(cl))
        }

        if (json.children) {
            json.children.forEach(childJson => JSONtoHTML(childJson, element));
        }
        else {
            if (json.userInput) {
                if (json.key in caseStudy['inputs']) {
                    if (json.key === 'caseStudyMainImg') {
                        // element.setAttribute('src', blog['inputs'][json.key])
                        element.setAttribute('src', '')
                        element.setAttribute('id', 'main-img')
                    }
                    else if (json.key === 'caseStudyTitle') {
                        element.textContent = caseStudy['inputs'][json.key]
                        element.classList.add('blog-title-main')
                    }
                    else {
                        element.textContent = caseStudy['inputs'][json.key]
                    }
                } else {
                    element.textContent = "";
                }
            }
            else if (json.takeFromState) {
                if (json.key === 'categories') {
                    let cats = caseStudy['categories'].sort().map(cat => `<div class='mx-1 category-tile py-1 px-2'><a href='/case-studies/category/${cat}'>${cat}</a></div>`)
                    element.innerHTML = cats.join('')
                }
                // if (json.key === 'authorName') {
                //     element = document.createElement('a')
                //     element.textContent = blog['author']['authorName']
                //     element.setAttribute('href', `/authors/${blog['authorId']}`)
                //     element.setAttribute('target', `_blank`)
                // }
                // else if (json.key === 'authorImg') {
                //     element.setAttribute('src', blog['author']['profileImg'])
                // }
            }
            else {
                element.textContent = json.textContent;
            }

        }
        parentElement.appendChild(element);
    }

    // const targetRef = useRef(null);

    // useEffect(() => {
    //     if (targetRef.current) {
    //         const observerOptions = {
    //             root: null,
    //             rootMargin: '0px',
    //             threshold: 0
    //         };

    //         const handleIntersection = (entries, observer) => {
    //             entries.forEach(entry => {
    //                 if (entry.isIntersecting) {
    //                     // You can perform any action here when the element reaches the top
    //                 } else {
    //                 }
    //             });
    //         };

    //         const barObserver = new IntersectionObserver(handleIntersection, observerOptions);
    //         if (targetRef.current) {
    //             barObserver.observe(targetRef.current);
    //         }

    //         return () => {
    //             barObserver.disconnect();
    //         };
    //     }
    // }, [targetRef]);

    function scrollFunction() {
        // var winScroll = document.body.scrollTop || document.documentElement.scrollTop;
        // var height = document.getElementById('additional-content').getBoundingClientRect().height
        // var scrolled = (winScroll / height) * 100;
        // if (scrolled > 100) scrolled = 100;
        // document.getElementById("myBar").style.width = scrolled + "%";
    }

    useEffect(() => {
        let storedData =JSON.parse(localStorage.getItem('form_data'));
        if(storedData){
            setFormFilled(true)
        }
        getCaseStudyReadStatus()

        document.body.scrollTop = 0
        document.addEventListener('wheel', function () { scrollFunction() });
        document.getElementById('root').style.height = '100vh' //So that the scroll progress bar can stick to the top
    }, [])

    return (
        <div className='bg-white'>

            {
                caseStudy ?
                    <HelmetProvider>
                        <Helmet>
                            <title>{caseStudy?.seoTitle}</title>
                            <link rel="canonical" href={GlobalUrl + '/case-studies/' + caseStudy['url']} />
                            <meta charset="utf-8" />
                            <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                            <meta name="csrf_token" content="" />
                            <meta property="type" content="website" />
                            <meta name="theme-color" content="#ffffff" />
                            <meta name="_token" content="" />
                            <meta name="robots" content="noodp" />
                            {
                                caseStudy['desc'] ?
                                    <meta name='description' content={caseStudy['desc']} /> : null
                            }
                            {
                                caseStudy['keywords'] ?
                                    <meta name='keywords' content={caseStudy['keywords'].join(', ')} /> : null
                            }
                            <meta property="og:locale" content="en_US" />
                            <meta property="og:type" content="article" />
                            <meta property="og:title" content={caseStudy['title']} />
                            <meta property="og:quote" content="quote" />
                            <meta property="og:hashtag" content="#ai" />
                            <meta content="image/*" property="og:image:type" />
                            <meta property="og:url" content={'www.sciative.com/blogs/' + caseStudy['url']} />
                            <meta property="og:site_name" content="Sciative Solutions - We Price Right" />
                            <meta property="og:description" content={caseStudy['desc']} />
                        </Helmet>

                        <section id='case-study-section' className='w-100'>

                        </section>

                        <section className='bg-white row'>
                            {/* <div ref={targetRef} className='w-100 sticky-section progress-section d-flex flex-column justify-content-end'>
                                <h5 className='mx-5 bold'>{currentHeading}</h5>
                                <div class="progress-container">
                                    <div class="my-progress-bar" id="myBar"></div>
                                </div>
                            </div>
                            <div className='col-0'>
                                {
                                    caseStudy ?
                                        <Contents url={caseStudy['url']} headings={headings} />
                                        : null
                                }
                            </div> */}
                            <div className='col-12 d-flex justify-content-center align-items-center mt-2'>
                                <div className='icon-item' onClick={shareCaseStudies}>
                                    <img src={share} alt="Share" />
                                    <div style={{fontWeight:"500"}}>Share</div>
                                </div>
                                <div className='icon-item' onClick={downloadPdf}>
                                    <img src={download} alt="Download" />
                                    <div style={{fontWeight:"500"}}>Download</div>
                                </div>
                            </div>
                            <div className='col-12'>
                                <div id="visible-container" className="container p-2">

                                </div>
                            </div>
                            {
                            !isAdditionalContentVisible ? (
                                <>
                                <div className="col-12 text-center mb-4">
                                    <button onClick={toggleAdditionalContent} className="read-more mt-2">
                                    {isAdditionalContentVisible ? "Read Less" : "Read More"}
                                    </button>
                                </div>
                                </>
                            ) : (
<></>
                            )
                            }


                            
                            <div className="col-12">
                        <div
                        id="casestudy-content"
                        className={`container p-2 additional-content ${
                            isAdditionalContentVisible ? "expanded" : "blurred"
                        }`}
                        >
                        {isAdditionalContentVisible ?(<>{parse(caseStudyContent)}</>): 
                        (<>
                        <div >
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Totam illo, eos recusandae beatae magnam cupiditate commodi corrupti quisquam odio laborum non nihil adipisci sint nobis deserunt hic eum, ratione tenetur, eligendi quidem culpa dicta? Cum earum in labore sequi, non obcaecati consectetur ut quasi, at assumenda distinctio a, ad sunt magnam amet cumque molestias ex maiores necessitatibus quae. Cupiditate, vitae!
                        </div>
                        </>)}
                        </div>
                    </div>
                        </section>

                        <section className='w-100 bg-white'>
                            <div className='container'>
                                <div className='w-100 row p-5'>
                                    <div className='col-sm-12 col-md-6 p-4'>
                                        <h4 className='bold'>Subscribe to our Newsletter</h4>
                                        <p className='thin mb-0'>Stay tuned with the latest industry updates.</p>
                                    </div>
                                    <div className='col-sm-12 col-md-6 p-2'>
                                        <div className='w-100 row'>
                                            <Form {...subscribeProps} />
                                            <small className='small-2 mt-2'>By clicking subscribe, you confirm that you understand and agree to the <a href='/privacy-policy'>Privacy Policy</a></small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {
                            caseStudy ?
                                <>
                                    <hr className='m-0' />
                                    <section className='w-100 bg-white p-3 row'>
                                        <div className='col-12'>
                                            <RecommendedCaseStudies categories={caseStudy['categories']} />
                                        </div>
                                    </section>
                                </>
                                :
                                null
                        }

                        <Contributor type='light'/>
                    </HelmetProvider>
                    :
                    null
            }


            <Modal
                show={pdfRequestModal}
                onHide={() => setPdfRequestModal(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                contentClassName="remove-white-bg"
                backdrop="static"
                centered
            >
                <Modal.Body className="p-0">
                    <CloseIcon onClick={() => setPdfRequestModal(false)} className='modal-close-icon' />
                    <Form {...pdfRequestProps} />
                </Modal.Body>
            </Modal>

            {/* <Dialog open={shareOpen} onClose={() => setShareOpen(false)}>
                <DialogTitle>Share this Link</DialogTitle>
                <DialogContent>
                    <Box display="flex" alignItems="center">
                    <TextField
                        fullWidth
                        variant="outlined"
                        value={linkToShare}
                        InputProps={{
                        readOnly: true,
                        }}
                        label="Link to Share"
                        margin="normal"
                    />
                    <IconButton onClick={handleCopyLink} color="primary">
                        <ContentCopyIcon />
                    </IconButton>
                    </Box>
                    {copied && <Box mt={1} color="green">Link copied to clipboard!</Box>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleShare('whatsappweb')} color="primary">
                    WhatsApp
                    </Button>
                    <Button onClick={() => handleShare('facebook')} color="primary">
                    Facebook
                    </Button>
                    <Button onClick={() => handleShare('linkedin')} color="primary">
                    LinkedIn
                    </Button>
                    <Button onClick={onClose} color="secondary">
                    Close
                    </Button>
                </DialogActions>
                </Dialog> */}


<Dialog open={shareOpen} onClose={() => setShareOpen(false)}
    maxWidth="sm" 
    fullWidth
    >
            <DialogTitle sx={{padding:"0.5em 1em"}}>
                Share this Link
                <IconButton
                    edge="end"
                    color="inherit"
                    onClick={onClose}
                    aria-label="close"
                    style={{ position: 'absolute', right: 8, top: 8 }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{padding:"1em 2em"}}>
                {/* Social media sharing icons */}
                <Box display="flex" justifyContent="space-around" mb={2} className="social-share">
                <IconButton 
                    onClick={() => handleShare('linkedin')} className='mx-1'
                    >
                    <LinkedinIcon size={45} />
                    </IconButton>
                    <FacebookShareButton 
              url={linkToShare} className='mx-1'
            >

                    <FacebookIcon size={45} />
            </FacebookShareButton>

                    <IconButton onClick={() => handleShare('whatsappweb')} className='mx-1'>
                    <WhatsappIcon size={45} />
                    </IconButton>
                    {/* <IconButton 
                    onClick={() => handleShare('facebook')} className='mx-1'
                    > */}

                    {/* </IconButton> */}


{/* 
            <LinkedinShareButton 
                        url={linkToShare} className='mx-1'
                        >
                        <LinkedinIcon size={30} />
                        </LinkedinShareButton> */}

                    {/* <IconButton onClick={() => handleShare('twitter')} className='mx-1'>
                    <TwitterIcon size={45}/>
                    </IconButton> */}
                    
            <TwitterShareButton url={linkToShare} className='mx-1'>
              <div className='p-1 bg-black twitter-btn d-flex justify-content-center align-items-center' style={{width: '45px', height: '45px'}}><i style={{fontSize:'1.5em'}} className=" text-white fa-brands fa-x-twitter"></i></div>
            </TwitterShareButton>
{/* 
            <EmailShareButton url={linkToShare} className='mx-1'>
              <EmailIcon size={30} />
            </EmailShareButton> */}

                </Box>


                <Box display="flex" alignItems="center">
                    <TextField
                        fullWidth
                        variant="outlined"
                        value={linkToShare}
                        InputProps={{
                            readOnly: true,
                        }}
                        label="Link to Share"
                        margin="normal"
                    />
                    <IconButton onClick={handleCopyLink} color="primary">
                        {copied ? (
                            <CheckCircleIcon style={{ color: 'green' }} />
                        ) : (
                            <ContentCopyIcon />
                        )}
                    </IconButton>
                </Box>

                {copied && <Box mt={1} color="green">Link copied to clipboard!</Box>}
            </DialogContent>
        </Dialog>


            <Dialog
                open={formModal}
                onClose={() => setFormModal(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth='md'
            >
                <DialogContent>
                    <section>
                        <Form
                            {...props}
                        />
                    </section>
                </DialogContent>
            </Dialog>

           
        </div>
    )
}

export default CaseStudyPage;