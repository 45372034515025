import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { BarChart } from '@mui/x-charts/BarChart';
import { PieChart } from '@mui/x-charts/PieChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import Alert from '@mui/material/Alert';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import EditIcon from '@mui/icons-material/Edit';
import { fetchToken } from '../../../Auth';
import axios from 'axios';
import { adminURL } from '../../../global';

const CaseStudiesAdmin = () => {

  const [fetch, setFetch] = useState(false);
  //Privileges
  const [superAdmin, setSuperAdmin] = useState(false);
  const [createPrivilege, setCreatePrivilege] = useState(false);
  const [livePrivilege, setLivePrivilege] = useState(false);
  const [editPrivilege, setEditPrivilege] = useState(false);
  const [deletePrivilege, setDeletePrivilege] = useState(false);

  const cols = useRef([])


  const [caseStudies, setCaseStudies] = useState([]);
  const [filteredCaseStudies, setFilteredCaseStudies] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [keywordsData, setKeywordsData] = useState([]);
  const [catData, setCatData] = useState([]);

  const [deleteModal, setDeleteModal] = useState(false);
  const [flaggedForDelete, setFlaggedForDelete] = useState('');

  const navigate = useNavigate();

  const getPrivileges = async () => {
    try {
      const response = await axios.get(`/get_privileges`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${fetchToken('nuprice_access_token')}`,
        },
      });
      if (response.status == 200 && response.data !== undefined) {
        let privileges = response.data['caseStudies']
        if (privileges['create']) { setCreatePrivilege(true) };
        if (privileges['makeLive']) { setLivePrivilege(true) };
        if (privileges['edit']) { setEditPrivilege(true) };
        if (privileges['delete']) { setDeletePrivilege(true) };
        if (response.data['superAdmin']) { setSuperAdmin(true) };
        setFetch(true);
      }
    } catch (err) {
      console.error("Error", err);
      if (err.response.status === 401) {
        navigate(`/niyantran-panel/${adminURL}/login`)
      }
    }
  }

  const getCaseStudies = async () => {
    try {
      const response = await axios.get(`/get_case_studies`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${fetchToken('nuprice_access_token')}`,
        },
      });
      if (response.status == 200 && response.data !== undefined) {
        let temp = []
        let cats = new Set()
        response.data.forEach((item, idx) => {
          temp.push({ id: idx + 1, caseStudyTitle: item['title'], url: item['url'], categories: [...item['categories'], ...item['customCategories']], created: item['created'], active: item['active'], keywords: item['keywords'], templateType: item['templateType'] })
          item['categories'].filter(cat => cat !== '').forEach(cat => cats.add(cat))
          item['customCategories'].filter(cat => cat !== '').forEach(cat => cats.add(cat))
        })
        setCategories(Array.from(cats).sort())
        setCaseStudies(temp)
        setFilteredCaseStudies(temp)

        let columns = [
          { field: 'id', headerName: 'Sr. No.', flex: 1, headerClassName: 'bg-grey-3 bold' },
          {
            field: 'caseStudyTitle',
            headerName: 'Case Study Title',
            sortable: true,
            filterable: false,
            width: 250, headerClassName: 'bg-grey-3 bold',
            renderCell: (params) => (
              <span className='bold' title={params.row.caseStudyTitle}>{params.row.caseStudyTitle}</span>
            )
          },
          {
            field: 'url',
            headerName: 'Case Study URL',
            sortable: true,
            filterable: false,
            flex: 1, headerClassName: 'bg-grey-3 bold',
            renderCell: (params) => (
              <span>
                {
                  params.row.templateType === 'page' ?
                    params.row.url : 'NA'
                }
              </span>
            )
          },
          {
            field: 'categories',
            headerName: 'Categories',
            sortable: false,
            filterable: false,
            flex: 1, headerClassName: 'bg-grey-3 bold',
            renderCell: (params) => (
              <span title={params.row.categories.join(', ')}>{params.row.categories.join(', ')}</span>
            )
          },
          {
            field: 'created',
            headerName: 'Created',
            sortable: true,
            filterable: false,
            flex: 1, headerClassName: 'bg-grey-3 bold'
          },
          {
            field: 'link',
            headerName: 'Link',
            sortable: false,
            filterable: false,
            flex: 1, headerClassName: 'bg-grey-3 bold',
            renderCell: (params) => (
              params.row.templateType === 'page' &&
              <IconButton onClick={() => window.open(`/case-studies/${params.row.url}`, '_blank')} aria-label="open-case-study">
                <OpenInNewIcon />
              </IconButton>
            )
          }
        ]
        if (livePrivilege) {
          columns.push({
            field: 'active',
            headerName: 'Live',
            sortable: false,
            filterable: false,
            flex: 1, headerClassName: 'bg-grey-3 bold',
            renderCell: (params) => (
              <Switch onClick={() => toggleSwitch(params.row.url, params.row.active)} checked={params.row.active} />
            )
          })
        }
        if (editPrivilege) {
          columns.push({
            field: 'edit',
            headerName: 'Edit',
            sortable: false,
            filterable: false,
            flex: 1, headerClassName: 'bg-grey-3 bold',
            renderCell: (params) => (
              <IconButton onClick={() => navigate(`/niyantran-panel/${adminURL}/edit-case-study/${params.row.url}`)} aria-label="open-case-study">
                <EditIcon />
              </IconButton>
            )
          })
        }
        if (deletePrivilege) {
          columns.push({
            field: 'delete',
            headerName: 'Delete',
            flex: 1, headerClassName: 'bg-grey-3 bold',
            sortable: false,
            filterable: false,
            renderCell: (params) => (
              <IconButton onClick={() => handleDelete(params.row.url)} aria-label="delete-case-study">
                <DeleteIcon />
              </IconButton>
            )
          })
        }

        cols.current = columns
      }
    } catch (err) {
      console.error("Error", err);
      if (err.response.status === 401) {
        navigate('/login')
      }
    }
  };

  const toggleSwitch = async (url, status) => {
    try {
      const response = await axios.post(`/change_case_study_status`, { url, toggleTo: !status }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${fetchToken('nuprice_access_token')}`,
        },
      });
      if (response.status == 200 && response.data !== undefined) {
        let temp = []
        response.data.forEach((item, idx) => temp.push({ id: idx + 1, caseStudyTitle: item['title'], url: item['url'], categories: [...item['categories'], ...item['customCategories']], created: item['created'], active: item['active'], keywords: item['keywords'],templateType:item['templateType'] }))
        setCaseStudies(temp)
        setFilteredCaseStudies(temp)
        setSelectedCategory(selectedCategory)
        alert('Status changed')
      }
    } catch (err) {
      console.error("Error", err);
      if (err.response.status === 401) {
        navigate(`/niyantran-panel/${adminURL}/login`)
      }
    }
  }

  const handleDelete = (url) => {
    setFlaggedForDelete(url);
    setDeleteModal(true)
  }

  const deleteCaseStudy = async () => {
    try {
      const response = await axios.post(`/delete_case_study`, { url: flaggedForDelete, editing: false }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${fetchToken('nuprice_access_token')}`,
        },
      });
      if (response.status == 200 && response.data !== undefined) {
        let temp = []
        response.data.forEach((item, idx) => temp.push({ id: idx + 1, caseStudyTitle: item['title'], url: item['url'], categories: [...item['categories'], ...item['customCategories']], created: item['created'], active: item['active'], keywords: item['keywords'] }))
        setDeleteModal(false);
        setCaseStudies(temp)
        setFilteredCaseStudies(temp)
      }
    } catch (err) {
      console.error("Error", err);
      if (err.response.status === 401) {
        navigate('/login')
      }
    }
  }

  const filterCaseStudies = () => {
    setFilteredCaseStudies(caseStudies.filter(cs => cs['categories'].includes(selectedCategory)))
  }

  const chartSetting = {
    yAxis: [
      {
        label: 'Number of Case Studies',
      },
    ],
    series: [{ dataKey: 'num' }],
    height: 300,
    sx: {
      [`& .${axisClasses.directionY} .${axisClasses.label}`]: {
        transform: 'translateX(-10px)',
      },
    },
  };

  function getFrequency(arr) {
    const frequency = {};

    for (let i = 0; i < arr.length; i++) {
      const element = arr[i];
      frequency[element] = (frequency[element] || 0) + 1;
    }

    return frequency;
  }

  function getTopKeywords(frequencyObj, k) {
    const sortedKeys = Object.keys(frequencyObj).sort((a, b) => frequencyObj[b] - frequencyObj[a]);
    return sortedKeys.slice(0, k);
  }

  const populateBarChart = () => {
    let allKeywords = []
    for (let cs of caseStudies) {
      allKeywords = [...allKeywords, ...cs['keywords']]
    }
    let frequencies = getFrequency(allKeywords)
    const topKeywords = getTopKeywords(frequencies, 5);
    setKeywordsData(topKeywords.map(key => ({ num: frequencies[key], key })))
  }

  const populatePieChart = () => {
    let allCats = []
    for (let cs of caseStudies) {
      allCats = [...allCats, ...cs['categories']]
    }
    allCats = allCats.filter(cat => cat !== '')
    let frequencies = getFrequency(allCats)
    const topCats = getTopKeywords(frequencies, 5);
    // setCatData(topCats.map(key => ({ num: frequencies[key], key })))
    let temp = []
    for (let i = 0; i < topCats.length; i += 1) {
      temp.push({ id: i, value: frequencies[topCats[i]], label: topCats[i] })
    }
    setCatData(temp)
  }

  useEffect(() => {
    getPrivileges();
  }, []);

  useEffect(() => {
    if (fetch) getCaseStudies();
  }, [fetch])

  useEffect(() => {
    if (selectedCategory) {
      console.log(selectedCategory,'this is selected cateogry')
      filterCaseStudies()
    }
  }, [selectedCategory])

  useEffect(() => {
    if (caseStudies.length) {
      populateBarChart()
      populatePieChart()
    }
  }, [caseStudies]);

  const constructSentence = () => {
    let sentence = 'You have';
    if (createPrivilege) {
      sentence += ' create, ';
    }
    if (livePrivilege){
      sentence += ' make live, '
    }
    if (editPrivilege) {
      sentence += ' edit, ';
    }
    if (deletePrivilege) {
      sentence += ' delete ';
    }
    sentence += ' access for Blogs';
    // let lastCommaIndex = sentence.lastIndexOf(",");
    // if (lastCommaIndex !== -1) {
    //   sentence = sentence.slice(0, lastCommaIndex) + " &" + sentence.slice(lastCommaIndex + 1);
    // }
    return sentence;
  };

  return (
    <>
      <section className='w-100 row p-2'>
        <div className='admin-card corner row p-3 shadow-2 w-100'>
          <div className='col-1'></div>
          <div className='col-8'>
            <h1 className='bold '>Case Studies Admin</h1>
            <p className='medium-3 text-muted'>View and manage all case studies created</p>
          </div>
          <div className='col-3 d-flex flex-column justify-content-center align-items-center'>
            {
              createPrivilege ?
                <Button variant="contained" size='large' onClick={() => navigate(`/niyantran-panel/${adminURL}/create-case-study`)}>Create New Case Study</Button>
                :
                <div class="alert alert-warning" role="alert">
                  You do not have create access.
                </div>
            }

          </div>
        </div>

        <div className='mt-3 admin-card corner row p-3 shadow-2 w-100'>

          <div className='mt-2 mb-4 w-100 row'>
            <div className='col-sm-12 col-md-6 d-flex flex-column justify-content-center'>
              <label className='medium mb-1 text-muted bold'>Filter by Category</label>
              <select value={selectedCategory} onChange={(evt) => setSelectedCategory(evt.target.value)} className='form-control'>
                <option selected hidden>Select an option</option>
                {categories.map(cat => <option value={cat}>{cat}</option>)}
              </select>
            </div>
            <div className='col-sm-12 col-md-6 d-flex justify-content-center align-items-center'>
            {
              !createPrivilege && !livePrivilege && !editPrivilege && !deletePrivilege ?
                <div className="w-100 alert alert-danger" role="alert">
                  <p className='mb-0 bold'>Access Rights</p>
                  <p className='mb-0 thin'>You do not have any privileges for managing Case Studies.</p>
                </div>
                :
                <div className="w-100 alert alert-info" role="alert">
                  <p className='mb-0 bold'>Access Rights</p>
                  <p className='mb-0 thin'>{constructSentence()}</p>
                </div>
            }

          </div>
          </div>
          
          {
            typeof cols !== 'undefined' && cols.current.length && caseStudies.length ?
              <div>

                <DataGrid
                  sx={{ borderCollapse: 'collapse', border: 'none' }}
                  rows={filteredCaseStudies}
                  columns={cols.current}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 10 },
                    },
                  }}
                  pageSizeOptions={[10, 20, 30, 40]}
                  checkboxSelection={false}
                  headerClassName="table-header"
                />
              </div>
              :
              <Alert severity="info">No Case Studies created.</Alert>
          }
        </div>

        <div className='mt-3 row p-3 w-100'>
          <div className='col-6 p-2'>
            <div className='h-100 admin-card p-3 corner shadow-2'>
              <h5 className='bold'>Case Studies by Categories</h5>
              <div className='mt-4 d-flex justify-content-center align-items-center'>
                <PieChart
                  series={[
                    {
                      data: catData,
                    },
                  ]}
                  width={700}
                  height={300}
                />
              </div>
            </div>
          </div>
          <div className='col-6 p-2'>
            <div className='admin-card p-3 shadow-2 corner'>
              <h5 className='bold'>Most used keywords</h5>
              <div className='d-flex w-100 mt-3 justify-content-center align-items-center'>
                {
                  keywordsData.length ?
                    <BarChart
                      dataset={keywordsData}
                      xAxis={[
                        { scaleType: 'band', dataKey: 'key', tickPlacement: 'middle', interval: 0, tickLabelPlacement: 'middle' },
                      ]}
                      {...chartSetting}
                    />
                    :
                    null
                }
              </div>
            </div>
          </div>
        </div>

      </section>

      <Dialog
        open={deleteModal}
        onClose={() => setDeleteModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth='sm'
      >
        <DialogTitle id="alert-dialog-title">
          <h5 className='bold'>{"Are you sure you want to delete this Case Study?"}</h5>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Case Study once deleted cannot be recovered later.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteModal(false)}>Cancel</Button>
          <Button variant='contained' onClick={deleteCaseStudy} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default CaseStudiesAdmin;